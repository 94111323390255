import React, { useState, useEffect, useMemo } from "react"
import Layout from "../../components/Layout"
import Img from "gatsby-image"
import SEO from "../../components/SEO/SEO"
import SeeAlso from "../../components/SeeAlso/SeeAlso"

import NavBar from "../../components/NavBar/NavBar"
import { isSSR } from "../../components/Script/script"
import { Title, Columns, Column, Button } from "../../StyleComponents/styles"
import { FlexDiv } from "../../StyleComponents/pagesStyle"

const GoogleAds = React.lazy(() => import("../../components/ADS/ADS"))

function GenericFoodGenerator(props) {
  const { node } = props.pageContext
  let [result1, setresult1] = useState([])

  useEffect(() => {
    randomizer(node.food)
  }, [])

  const generate = () => {
    randomizer(node.food)
  }
  const randomizer = list => {
    let NamesData = []
    for (let i = 0; i < 7; i++) {
      let random = Math.floor(Math.random() * list.length)
      NamesData.push({
        FoodName: list[random].FoodName,
        Group: list[random].Group,
        SubGroup: list[random].SubGroup,
      })
    }
    setresult1(NamesData)
  }
  return (
    <Layout location={props.location}>
      <SEO
        title={`Random ${node.name} Generator`}
        description={`Random Generic Food Name Generator,  Generate Random Generic Food Names, Simple generate Generic Food names and Generic Food code online.`}
        keywords={[
          "Generic Food name, Generic Food Name generator,Random name of Generic Food,Random Generic Food online, random Generic Food name generator, random Generic Food name,make Generic Food name, Generic Food generator, random Generic Food name and code, random Generic Food code names, random code Generic Food names,random Generic Food names",
        ]}
      />
      <NavBar
        listPages={useMemo(() => {
          return [
            { name: "Generator", link: "/generator/" },
            { name: `${node.name} Generator`, link: `/${node.path}/` },
          ]
        }, [node.name, node.path])}
      />
      <div className="container">
        <Title>Random Generic Food Name Generator</Title>
        <br />
        <Columns>
          <Column>
            <FlexDiv textAlign="center" minHeight="400px">
              <Button
                className="mb-3"
                borderColor="#1da1f2"
                color="#1da1f2"
                hoverColor="white"
                hoverBorderColor="#1da1f2"
                hoverBackgroundColor="#1da1f2"
                fontSize="1.5rem"
                value="Generate"
                onClick={generate}
              >
                Generate
              </Button>
              <br />
              <div>
                {result1.map((e, index) => {
                  return (
                    <p className="h5" key={index}>
                      Name: {e.FoodName}
                      <br />
                      Group: {e.Group}
                      <br />
                      SubGroup: {e.SubGroup}
                    </p>
                  )
                })}
              </div>
            </FlexDiv>
          </Column>
          <Column>
            {/* google ads UnitPediaRight */}
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <GoogleAds
                  slot="1189740279"
                  data-ad-format="auto"
                  data-ad-layout-key="-gi+g+7v-1q-6r"
                  data-full-width-responsive="true"
                />
              </React.Suspense>
            )}
          </Column>
        </Columns>
        <FlexDiv maxWidth="250px" margin="auto">
          <Img
            fluid={node.img.childImageSharp.fluid}
            alt="generic food generator"
          />
        </FlexDiv>
        <br />
        <SeeAlso seeAlsoList={node.SeeAlso} />
        <br />
      </div>
    </Layout>
  )
}

export default GenericFoodGenerator
